* {
  box-sizing: border-box;
  font-family: $mainFont;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.45px;
  color: $black;
  background: $white;

  .overlay-div {
    position: fixed;
    display: block;
    height: 100%;
    width: 100%;
    max-height: 100vh;
    max-width: 100vw;
    top: 0;
    left: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.7);
    content: '';
    z-index: -1;
    transition: opacity 0.3s ease-in-out;
  }

  &.overlay {
    overflow: hidden;

    .overlay-div {
      opacity: 1;
      z-index: 99;
    }
  }
}

div,
header,
nav,
aside,
section,
article {
  margin: 0;
  padding: 0;
}

textarea,
input,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1em;

  &:focus {
    outline: none;
  }
}

img {
  vertical-align: middle;
  border: none;
}

p, a, span, h1, h2, h3, h4, h5, li {
  &.neutra-bold {
    font-family: $boldFont;
  }

  &.neutra-demi {
    font-family: $demiFont;
  }

  &.lato-font {
    font-family: $latoFont;
  }
}

strong {
  font-family: $demiFont;
}

p {
  margin: 15px 0;

  &.single-space {
    margin: 0;
  }
}

a {
  border: none;
  color: $black;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover, &.current, &.yellow-link {
    color: $linkColor;
  }

  &.yellow-link {
    &:hover {
      color: darken($linkColor, 10%);
    }
  }
}

.strike-text {
  position: relative;
  display: inline-block;
  text-align: center;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    width: 60px;
    top: 40%;
    background: $gold;
  }

  &:before {
    left: -80px;
  }

  &:after {
    right: -80px;
  }

  &.black {
    &:before, &:after {
      background: $black;
    }
  }

  &.white {
    &:before, &:after {
      background: $white;
    }
  }

  &.under {
    &:before {
      display: none;
    }

    &:after {
      width: 30%;
      height: 4px;
      top: auto;
      bottom: -10px;
      left: 0;
    }
  }
}

h1, h2, h3, .title-1, .title-2, .title-3 {
  font-family: $demiFont;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
}

.title-1, h1 {
  @include font-size(32px);

  line-height: 36px;
}

.title-2, h2 {
  @include font-size(24px);

  line-height: 28px;
}

.title-3, h3 {
  @include font-size(20px);

  line-height: 24px;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.centered {
  text-align: center;
}

.clearfix {
  overflow: hidden;
  clear: both;

  &.absolute-clearfix {
    overflow: visible;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.box-shadow {
  @include dropshadow;
}

.main-container {
  background: $lightGrey;
  padding-top: 50px;

  @media screen and (min-width: $mediumScreen) {
    padding-top: 123px;
  }

  &.white-bg {
    background: $white;
  }

  a {
    font-family: $latoFont;
  }
}

.vertical-align-wrapper {
  @include vertical-align;
}

////////////////////
//STICKY FOOTER
////////////////////

.wrapper {
  position: relative;

  @media screen and (min-width: $smallScreen) {
    min-height: 100%;
    margin: 0 auto -630px;
  }
}

@media screen and (min-width: $smallScreen) {
  footer, .push {
    height: 630px;
  }
}

////////////////////
// HEADER
////////////////////

header {
  .header-facebook {
    display: inline-block;
    float: right;
    margin: 7px 0 0 20px;
    height: 24px;
    width: 24px;
    background: url("/assets/images/h-facebook.svg") no-repeat;
  }

  .desktop-header {
    display: none;
    background: $white;
    position: fixed;
    z-index: 9999;
    width: 100%;

    @media screen and (min-width: $mediumScreen) {
      display: block;
    }

    .top-header {
      background: $lightGrey;
      text-align: right;
      padding: 0 20px;

      @media screen and (min-width: $largeScreen) {
        padding: 0;
      }

      .top-header-menu {
        display: inline-block;

        a {
          text-transform: uppercase;
          margin-right: 20px;
          font-size: 12px;
        }
      }

      .header-phone {
        display: inline-block;
        margin: 0;
        padding: 10px 12px;
        font-size: 16px;
        color: $white;
        font-family: $latoFont;
        font-weight: 500;
        background: $gold;
      }
    }

    .main-header {
      padding: 0 20px;

      @media screen and (min-width: $largeScreen) {
        padding: 0;
      }

      .header-logo {
        transition: all 0.3s ease-in-out;
        padding: 35px 0 15px;
        display: inline-block;

        img {
          transition: all 0.3s ease-in-out;
          max-width: 227px;
        }
      }

      .header-menu {
        padding: 50px 0 0;
        transition: padding 0.3s ease-in-out;

        a {
          font-size: 16px;
          text-transform: uppercase;
          margin-right: 40px;
          position: relative;

          @media( max-width: 1120px) {
            margin-right: 20px;
          }

          @media (max-width: 1060px) {
            font-size: 14px;
          }

          &:last-child {
            margin-right: 0;
          }

          &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            background: $gold;
            opacity: 0.5;
            width: 0;
            height: 4px;
            top: 25%;
            transition: width 0.35s ease-in-out;
          }

          &:before {
            left: -10px;
          }

          &:after {
            right: -10px;
          }

          &:hover, &.current {
            color: inherit;

            &:before, &:after {
              width: calc(100% + 18px);
            }
          }
        }
      }
    }

    &.fixed-state {
      .main-header {
        .header-logo {
          padding: 26px 0 18px;
          img {
            max-width: 180px;
          }
        }

        .header-menu {
          padding-top: 35px;
        }
      }
    }
  }

  .mobile-menu {
    display: block;
    position: fixed;
    z-index: 9999;
    width: 100%;
    background: $white;
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);
    max-height: 100vh;
    overflow: scroll;

    @media screen and (min-width: $mediumScreen) {
      display: none;
    }

    .menu-wrapper {
      padding: 10px 0;

      .header-logo {
        display: inline-block;
        padding-left: 15px;

        img {
          max-width: 160px;
        }
      }

      .header-facebook {
        margin: 2px 15px 0 0;
      }

      .hamburger {
        padding: 2px 15px 0 0;
        float: right;
      }
    }

    .header-nav {
      display: none;

      .main-menu {
        .menu-links {
          display: block;
          padding: 20px 20px 10px;
          font-size: 20px;
          text-transform: uppercase;
          border-bottom: 1px solid $grey;

          &:first-of-type {
            box-shadow: inset 0 10px 15px -10px rgba(0, 0, 0, 0.2);
          }
        }
      }

      .sub-menu {
        background: $lightGrey;
        padding: 20px;

        .menu-links {
          display: block;
          font-size: 16px;
          text-transform: uppercase;
          margin: 10px 0;

          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin: 0;
          }
        }
      }

      .header-phone {
        display: block;
        width: 100%;
        font-size: 20px;
        background: $gold;
        margin: 0;
        padding: 15px 0;
        text-align: center;
        color: $white;
        font-family: $latoFont;
      }
    }
  }
}

////////////////////
// FOOTER
////////////////////

footer {
  background: $white;

  .footer-newsletter {
    padding: 40px;
    text-align: center;
    border: 2px dotted $gold;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+1,00A758+100&0+73,0.1+100 */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 1%, rgba(248, 215, 92, 0) 73%, rgba(245, 200, 31, 0.1) 100%);

    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 1%, rgba(248, 215, 92, 0) 73%, rgba(245, 200, 31, 0.1) 100%);

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 1%, rgba(248, 215, 92, 0) 73%, rgba(245, 200, 31, 0.1) 100%);

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#1af5c81f',GradientType=0 );

    /* IE6-9 */

    .newsletter-form {
      margin-top: 40px;

      .newsletter-input {
        width: 100%;
        max-width: 530px;
        margin: 0 0 20px;
        padding-bottom: 5px;
        font-size: 18px;
        border: 0;
        border-bottom: 1px solid $black;
        background: transparent;

        @media screen and (min-width: $mediumScreen) {
          margin-right: 40px;
        }
      }

      .newsletter-submit {
        border: 0;
        font-size: 14px;
        padding: 14px 25px 10px;
        margin: 0;
      }
    }
  }

  .footer-bottom {
    padding: 40px;
    background: #000;
    text-align: center;

    @media screen and (min-width: $mediumScreen) {
      text-align: left;
      padding: 55px;
    }

    p, h3, a {
      color: $white;
    }

    .f-logo {
      max-width: 227px;
    }

    &.footer-contact {
      span, a {
        color: $linkColor;
        font-family: $latoFont;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
