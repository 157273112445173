.search-form{

  input{

    border: 0;
    padding: 20px;
    font-size: 18px;
    width: 100%;
    background: url('/assets/images/search-picto.svg') no-repeat center right 20px;
  }
}

.show-search-container{

  text-align:center;
  position:relative;
  margin-top: -168px;
  color: $white;

  padding: 0 40px;

  @media screen and (min-width: $smallScreen){


    padding: 0 80px;
  }

  p{

    font-size: 18px;
    margin: 20px 0;
  }

  .keyword-search{

    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 20px;
    font-size: 18px;
    border: 0;
    border-bottom: 1px solid $white;
    background: transparent;
    color: $white;

    @media screen and (min-width: $smallScreen){

      width: calc(100% - 174px);
      margin-bottom: 0;
    }

    @media screen and (min-width: $mediumScreen){

      margin-right: 20px;
    }
  }

  .keyword-submit{

    border: 0;
    font-size: 14px;
    padding: 14px 25px 10px;
    margin: 0;
  }

  .categories-holder{

    padding: 12px 24px;
    background: $white;
    width: 100%;
    text-align: center;
    @include dropshadow();

    @media (max-width: 767px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    .button-holder{

      display: inline-block;
      text-align:center;
      appearance: none;
      margin: 10px;

      @media screen and (min-width: $smallScreen){
        width: 65px;
      }

      button{

        cursor: pointer;
      }

      label{

        display: none;
        color: $black;
        margin-top: 10px;

        @media screen and (min-width: $smallScreen){

          display: block;
        }
      }

      .btn-wrapper{

        position: relative;
        width: 38px;
        height: 38px;
        margin: auto;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;

        &:hover{

          opacity: 0.7;
        }

        .cat-holder{

          position: absolute;
          top: 0px;
          left: 0px;
          opacity: 0;
          z-index: 999;
        }

        .btn-overlay{

          height: 100%;
          width: 100%;
          opacity: 1;
          z-index: 1;
        }
      }
    }
  }

  .filters-holder{

    margin: 30px 0;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 879px) {
      flex-direction: column;
    }

    label {
      color: $black;
      text-transform: uppercase;
      padding: 10px 15px 10px 0;
      margin-right: 15px;
      width: 20%;
      text-align: left;

      @media screen and (max-width: 879px) {
        width: 100%;
        text-align: center;
      }
    }

    .select-wrapper{
      overflow: hidden;
      width: 100%;
      position: relative;
      display: flex;

      &:after{

        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 42px;
        height: 100%;
        background: url('/assets/images/select-arrow.svg') $gold no-repeat center;
        border-radius: 0 2px 2px 0;
        pointer-events: none;
      }

      select {
        width: 100%;
        border: none;
        background: $white;
        padding: 10px 15px;
        font-size: 18px;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }

  }
}
