.promotions-container{

  padding: 40px;
  background: $lightGrey;
  position: relative;

  @media screen and (min-width:$smallScreen){

    padding: 80px;
  }

  @media screen and (min-width: $mediumScreen){

    padding: 80px 0;
  }

  .promotion-card{

    background: $white;
    width: 100%;
    text-align:center;


    @media screen and (min-width: $smallScreen) and (max-width: $mediumScreen){

      margin: 0 20px;
    }

    @media screen and (min-width: $mediumScreen){

      width: 33%;

      &.featured{

        position: absolute;
        left: calc(33% - 20px);
        width: calc(33% + 48px);
        top: -16px;

        @include dropshadow();
      }
    }

    .img-wrapper{

      display: block;
      position: relative;
      overflow:hidden;

      .promotion-img{

        width: 100%;
        transform: scale(1, 1);
        transition: all 1s cubic-bezier(0, 0, 0.175, 1);

      }

      &:hover{

        .promotion-img{

          transform: scale(1.1, 1.1);
        }
      }
    }

    .promotion-info{

      padding: 40px;

      .title-3{

        margin:0 0 8px;
      }

      .promotion-text{

        font-size: 18px;
        line-height: 24px;
        margin: 0 0 30px;
      }

      .button {
        margin: 0;
      }

    }
  }

  .nav-arrows{

    top: 48%;

    @media screen and (min-width: $smallScreen){

      top: 50%;

    }

    @media screen and (min-width: $mediumScreen){

      display: none;
    }
  }
}

.news-container{

  padding: 40px;
  background: $white;

  @media screen and (min-width:$smallScreen){

    padding: 80px;
  }

  @media screen and (min-width: $mediumScreen)
  {
    padding: 80px 0;
  }
  .news-content{

    background: $lightGrey;

      .news-wrapper{

        padding: 40px;

        @media screen and (min-width: $smallScreen){

          padding: 50px;
          @include vertical-align();
        }

        @media screen and (min-width: $largeScreen){

          padding: 145px 90px;
        }
      }

    p:last-child{

      margin-bottom: 0;
    }
  }
}
