/*
 * 		Colors
 * 		------
 */

$white              : #ffffff;
$black		    	    : #262724;
$lightGrey          : #F4F4F4;
$grey               : #DBDBDB;
$gold               : #00A758;
$blue               : #324D8F;
$red                : #E91D23;

$linkColor          : #00A758;
$lightText          : #4C535B;



/*
 * 		Fonts
 * 		-----
 */
@import 'https://fonts.googleapis.com/css?family=Lato:300,400,700';


@font-face {
  font-family: NeutraBold;
  src: url('/assets/fonts/NeutraBold.eot') format('eot'), url('/assets/fonts/NeutraBold.woff') format('woff'), url('/assets/fonts/NeutraBold.otf');
  font-weight: normal;
}

@font-face {
  font-family: NeutraBook;
  src: url('/assets/fonts/NeutraBook.eot') format('eot'), url('/assets/fonts/NeutraBook.woff') format('woff'), url('/assets/fonts/NeutraBook.otf');
  font-weight: normal;
}


@font-face {
  font-family: NeutraDemi;
  src: url('/assets/fonts/NeutraDemi.eot') format('eot'), url('/assets/fonts/NeutraDemi.woff') format('woff'), url('/assets/fonts/NeutraDemi.otf');
  font-weight: normal;
}


$mainFont 	 	: 'NeutraBook', sans-serif;
$boldFont     : 'NeutraBold', sans-serif;
$demiFont     : 'NeutraDemi', sans-serif;
$latoFont     : 'Lato', sans-serif;

/*
 * 		Breakpoints
 * 		-----------
 */

$exTinyScreen       : 320px;
$tinyScreen         : 480px;
$smallScreen        : 768px;
$mediumScreen       : 1024px;
$largeScreen        : 1200px;
$exLargeScreen      : 1350px;
