.one-third {
  margin-bottom: 40px;

  &:last-of-type {
    margin: 0;
  }

  @media screen and (min-width: $mediumScreen) {
    float: left;
    margin: 0 50px 0 0;
    width: calc(33.33333% - 50px);
  }
}

.one-fourth {
  margin-bottom: 40px;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (min-width: $mediumScreen) {
    float: left;
    margin-right: 40px;
    width: calc((100% - (3 * 40px)) / 4);
  }
}

.button {
  display: block;
  width: 100%;
  padding: 12px 25px;
  margin: 0px 12px 10px 0;
  text-transform: uppercase;
  color: $white;
  background: $gold;
  border-radius: 2px;
  transition: background 0.3s ease-in-out;
  cursor: pointer;

  &.grey {
    background: $black;
  }

  &.pdf {
    background-repeat: no-repeat;
    background-image: url("/assets/images/pdf-picto.svg");
    background-position: center right 15px;
    padding-right: 60px;
  }

  @media screen and (min-width: $smallScreen) {
    display: inline-block;
    width: auto;
  }

  &:hover {
    color: $white;
    background: darken($gold, 8%);

    &.grey {
      background: lighten($black, 20%);
    }

    &.pdf {
      background-repeat: no-repeat;
      background-image: url("/assets/images/pdf-picto.svg");
      background-position: center right 15px;
    }
  }
}

.half {
  width: 100%;

  @media screen and (min-width: $smallScreen) {
    width: 50%;
    float: left;
  }
}