.show-container {

  background: $white;

  .artist {

    margin:5px 0 0;
  }

  .show-name {

    margin: 0 0 20px;

    &.title-3{

      margin-bottom: 0;
    }
  }

  .show-name, .show-summary {

    font-size: 18px;
    line-height: 24px;
  }

  .show-details {

    .show-status {

      font-size: 14px;
      text-transform: uppercase;
      margin: 0;
      font-weight: bolder;

      &.initial-date {
        color: $blue !important;
      }

      &.is-limited {
        color: $red !important;
      }
    }

    .show-price {

      margin: 5px 0 20px;
      font-size: 18px;
      text-transform: uppercase;

      span {

        color: $linkColor;
      }
    }

    .button {

      padding: 12px 0 10px;
      text-align: center;

      @media screen and (min-width: $smallScreen) {

        width: 105px;
      }

      &:last-of-type {

        margin: 0;
      }
    }

    &.sold-out, &.postponed, &.cancelled {

      .show-status {

        color: $red;
      }

      .button {

        &.buy-button {

          display: none;
        }

        &.more-details {

          width: 100% !important;
          margin-right: 0 !important;
        }
      }
    }

    &.extra {

      .show-status {

        color: $black;
      }
    }
  }

  .show-category {

    height: 38px;
    width: 38px;
    z-index: 1;
    pointer-events: auto;

    &.cat-all {

      background-image: url('/assets/images/categories/all.svg');
    }
    &.cat-humour {

      background-image: url('/assets/images/categories/humour.svg');
    }
    &.cat-music {

      background-image: url('/assets/images/categories/music.svg');
    }
    &.cat-theater {

      background-image: url('/assets/images/categories/theater.svg');
    }
    &.cat-dance {

      background-image: url('/assets/images/categories/dance.svg');
    }
    &.cat-kids {

      background-image: url('/assets/images/categories/kids.svg');
    }
    &.cat-other {

      background-image: url('/assets/images/categories/others.svg');
    }
    &.cat-chanson {

      background-image: url('/assets/images/categories/chanson.svg');
    }
    &.cat-parole {

      background-image: url('/assets/images/categories/parole.svg');
    }
  }

  .all-card {

    border: 2px dotted $gold;
    padding: 60px 25px;
    background: $white;
    position: relative;

    @media screen and (min-width: $smallScreen) {

      padding: 110px 58px;
    }

    &:hover {

      a {

        color: darken($linkColor, 7%);
      }
    }

    .link-wrapper {

      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      left: 0;
    }

    a {

      display: block;
      color: $linkColor;
      text-decoration: underline;
      @include font-size(30px);
      line-height: 34px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .loop-picto {

      display: block;
      width: 69px;
      height: 33px;
      margin: 30px auto 0;
      background: url('/assets/images/see-all.svg') no-repeat center;
    }
  }

  //INDIVIDUAL CARDS


  &.cards-holder {

    padding: 40px 20px;
    position: relative;
    text-align: center;


    @media screen and (min-width: $smallScreen) {

      padding: 80px;
    }

    .headings {

      margin-bottom: 40px;

      a {

        display: block;
        font-size: 18px;
        margin-top: 20px;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }

    .card-wrapper {

      @media screen and (min-width: $smallScreen) {

        padding-left: 20px;

      }
    }

    .show-info-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }

    .show-card {

      display: flex;
      flex-direction: column;
      position: relative;
      border: 2px dotted $gold;
      text-align: left;
      //padding-bottom: 120px; //biggest height for show-details
      min-height:675px;

      @media screen and (min-width: $smallScreen){

        min-height:600px;
      }

      @media screen and (min-width: $mediumScreen){

        min-height:675px;
      }


      .img-wrapper{

        display: block;
        overflow: hidden;

        .show-img {

          display: block;
          width: 100%;
          transform: scale(1, 1);
          transition: all 1s cubic-bezier(0, 0, 0.175, 1);
          min-height: 295px;
          //background-size: cover;
          background-size: 100% auto;
          background-repeat: no-repeat;
        }

        @media screen and (min-width: $mediumScreen){
          &:hover{

            .show-img{

              transform: scale(1.1, 1.1);
            }
          }
        }
      }

      .show-date {

        background: $black;
        padding: 10px;
        display: block;
        top: 0;
        left: 0;
        font-size:18px;
        text-align:center;
        color: $white;
        font-family: $latoFont;
        text-transform: uppercase;
        margin: 0;
        z-index: 1;

      }

      .show-category {

        position: absolute;
        top: 60px;
        right: 20px;
      }

      .show-info {

        padding: 15px 7.2%;

      }

      .show-details {

        padding: 0 7.2% 15px;
        position: absolute;
        bottom: 0;
        width: 100%;

        .show-price {

          margin-bottom: 15px;

          span {

            font-family: $latoFont;
          }
        }

        .button {

          font-size: 14px;
          width: calc(50% - 6px);
        }
      }
    }

    //CLUB CARDS

    &.club-shows {

      background: #000;
      text-align: center;
      position: relative;

      .headings {

        margin-bottom: 40px;

        .title-1 {

          color: $white;
        }
      }

      .show-card {

        background: $white;
        color: $black;
        .club-logo {

          position: absolute;
          display: block;
          width: 75px;
          height: 40px;
          top: 70px;
          left: 0;
          background: url('/assets/images/le-club.jpg') no-repeat;
          background-size: cover;
          opacity: 0.95;
          z-index: 1;

          @media screen and (min-width: $smallScreen) {

            left: -20px;
            opacity: 1;
          }
        }

      }

    }

    //LISTING CARDS


    &.show-listing {

      &:after {
        content:"";
        display:block;
        clear:both;
      }

      @media screen and (min-width: 768px) {

        padding: 40px;

      }

      @media screen and (min-width: 1457px) {

        padding: 80px 80px 50px;

      }

      .card-wrapper {

        margin-bottom: 20px;
        width: 100%;

        &:last-of-type {

          margin-right: 0;
        }

        @media screen and (min-width: $smallScreen) {

          margin-bottom: 35px;
          margin-right: 15px;
          width: calc((100% - (15px * 2)) / 2);

        }

        @media screen and (min-width: $mediumScreen) {

          width: calc((100% - (15px * 3)) / 3);

        }

        @media screen and (min-width: 1440px) {

          width: calc((100% - (15px * 4)) / 4);

        }

        @media screen and (min-width: 1791px) {

          width: calc((100% - (15px * 5)) / 5);
        }

        @supports (-ms-ime-align:auto) {
          .date-card {
            max-height: 675px;
            display: flex;

            @media(min-width: 768px) {
              justify-content: center;
              align-items: center;
              flex-direction: column;
            }
          }
        }

        .date-card {

          border: 2px dotted $gold;
          padding: 30px 25px;


          //@-moz-document url-prefix(), {
            max-height: 675px;
            display: flex;

            //@media(min-width: 768px) {
              justify-content: center;
              align-items: center;
              flex-direction: column;
            //}
          //}

          &.long-date {
            @media (min-width: 300px) and (max-width: 450px) {
              padding: 30px 15px;
            }
          }

          @media screen and (min-width: $smallScreen) and (max-width: 1130px) {
            padding: 0 30px;
          }

          @media screen and (min-width: 1130px) and (max-width: 1330px) {
            padding: 0 50px;
          }

          .card-month {

            font-family: $demiFont;
            font-size: 3.25rem;
            text-transform: uppercase;
            word-break: break-all;
            line-height: 84px;
            letter-spacing: 2px;
            margin: 0;

            @media (min-width: 320px) and (max-width: 450px) {
              font-size: 2.5rem;
              line-height: 64px;
            }

            @media screen and (min-width: $smallScreen){
              //@-moz-document url-prefix() {
              //top: 0 !important;
              //transform: none !important;
              //}

              letter-spacing: 10px;
              writing-mode: vertical-lr;
              text-orientation: upright;
              margin-left: auto;
              margin-right: auto;

              //@include vertical-align();
            }

            &.long-month {
              @media (min-width: 300px) and (max-width: 450px) {
                font-size: 2rem;
                line-height: 64px;
              }
            }

            /*@media screen and (min-width: $smallScreen) and (max-width: $largeScreen) {

              font-size: 3rem;
              line-height: 64px;
            }*/

            &:before, &:after {

              content: '';
              display: block;
              width: 50%;
              height: 3px;
              margin: auto;
              background: $black;
            }

            &:before {

              margin-bottom: 30px;
            }

            &:after {

              margin-top: 30px;
            }
          }
        }

      }
    }
  }
}

//CATEGORIES

.cat-holder {

  border: none;
  background-color: transparent;
  width: 38px;
  height: 38px;
  background-repeat: no-repeat;
  background-position: 0 0;
  z-index: 1;
  cursor: pointer;

  &.current-cat, &.checked {

    background-position: 0 -39px;
    opacity: 1;
  }

  &.cat-all {

    background-image: url('/assets/images/categories/all.svg');
  }
  &.cat-humour {

    background-image: url('/assets/images/categories/humour.svg');
  }
  &.cat-music {

    background-image: url('/assets/images/categories/music.svg');
  }
  &.cat-theater {

    background-image: url('/assets/images/categories/theater.svg');
  }
  &.cat-dance {

    background-image: url('/assets/images/categories/dance.svg');
  }
  &.cat-kids {

    background-image: url('/assets/images/categories/kids.svg');
  }
  &.cat-other {

    background-image: url('/assets/images/categories/others.svg');
  }
  &.cat-chanson {

    background-image: url('/assets/images/categories/chanson.svg');
  }
  &.cat-parole {

    background-image: url('/assets/images/categories/parole.svg');
  }
}

.slick-wrapper {
  .show-card {
    min-height: 675px !important;
  }
}
