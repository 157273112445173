/*
 * 		Settings
 * 		--------
 */
 @import  'settings';
 @import  'mixins';
 @import  'animation';
 
 
 /*
  * 		Components
  * 		----------
  */
 
 @import  'components/common';
 @import  'components/hamburgers/hamburgers';
 @import  'components/slick';
 @import  'components/slick-theme';
 @import  'components/carousel';
 @import  'components/mCustomScrollbar';
 @import  'components/featherlight';
 @import  'components/featherlight.gallery';
 
 
 /*
  * 		Modules
  * 		-------
  */
 
 @import  'modules/blocks';
 @import  'modules/hero';
 @import  'modules/facebook-like';
 @import  'modules/search';
 @import  'modules/show-blocks';
 @import  'modules/show-more';
 
 /*
  * 		Templates
  * 		---------
  */
 
 @import 'templates/index';
 @import 'templates/show-detail';
 @import 'templates/generic';
 @import 'templates/contact';
 